/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

form
	div.DivForm
		h3
			margin-bottom: $SPACE_LITTLE
			font-size: $FONT_LABEL_H3
		fieldset
			legend
				font-size: $FONT_LABEL_LEGEND
				margin-top: $SPACE_LITTLE
				margin-bottom: $SPACE_LITTLE
			label
				font-size: $FONT_LABEL_GENERAL
				margin-bottom: $SPACE_NARROW
				margin-top: $SPACE_SMALL
				display: block
			label.LabelPreffix
				margin-right: $SPACE_LITTLE
			label.LabelSuffix
				margin-left: $SPACE_LITTLE
		span.SpanCustomerPhone
			display: flex !important
			select
				width: $WIDTH_INPUT_PHONE
				margin-right: $SPACE_LITTLE
	label.LabelAttachment
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		+setBorderRadius(50%)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-size: $FONT_LABEL_H2
		span
			font-size: $FONT_LABEL_SUBTITLE
	div.DivForm
		h3
			display: block
			margin-top: $SPACE_MEDIUM
			margin-bottom: $SPACE_LITTLE
			font-size: $FONT_LABEL_H3
		h3:first-of-type
			margin-top: 0px
		label
			font-size: $FONT_LABEL_GENERAL
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_SMALL
			display: block
		label.mat-form-field-label
			line-height: auto
			margin-top: -$SPACE_LITTLE
			margin-bottom: 0px
		span, p, textarea#spanIncidentNote
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			display: block
		span#Rupiah
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
		.mat-form-field, .legend-wrap
			span
				font-size: inherit
				margin-bottom: 0px
				margin-top: 0px
				display: inline-block
				.legend-label-text
					word-break: break-word
					white-space: normal
		.legend-wrap
			.horizontal-legend
				li
					display: block

// GENERAL - END


// NAVIGATION - START

nav
	figure
		text-align: center
		span#spanProfileNameInitial
			font-size: $FONT_LABEL_PROFILENAMEINITIAL
			display: block
		figcaption
			span#spanProfileName, span#spanProfilePosition
				display: block
			span#spanProfileName
				font-size: $FONT_LABEL_PROFILENAME
			span#spanProfilePosition
				font-size: $FONT_LABEL_PROFILEPOSITION
		a.SignOut
			font-weight: 600
	ul
		list-style-type: none
		li
			margin-top: $SPACE_LARGE
			margin-bottom: $SPACE_LARGE
	ul.ListNavigation
		li.ListItemNavigation
			font-size: $FONT_LIST_NAVIGATIONITEM
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				li.ListItemSubNavigation
					font-size: $FONT_LIST_NAVIGATIONSUBITEM

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	header#headerSignIn
		h1, span
			display: block
		h1
			font-size: $FONT_LABEL_H1
			letter-spacing: $SPACE_LITTLE
			margin-bottom: -$SPACE_LITTLE
		span
			font-size: $FONT_LABEL_SPAN

div#divBodySignIn
	div.DivEmailNotRegistered
		a.AContactUs
			text-decoration: underline

// SIGN IN - END


// DASHBOARD - START

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		margin-top: $SPACE_MEDIUM
		margin-bottom: $SPACE_MEDIUM
		text-align: center
		span.SpanHighlight
			padding-top: $SPACE_LITTLE
			padding-bottom: $SPACE_LITTLE
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			margin: $SPACE_LITTLE
			min-width: $WIDTH_LABEL_HIGHLIGHT
			display: block
			+setBorderRadius($RADIUS_LABEL_HIGHLIGHT)

form div.DivForm fieldset, div.DivForm
	ul
		list-style: none
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		div.DivListChart
			span
				font-size: $FONT_DASHBOARD_SPAN
			ul
				padding: $SPACE_LITTLE
				+setBorderRadius($RADIUS_DASHBOARD_LIST)
				li
					list-style: none
					margin-top: $SPACE_NARROW
					margin-bottom: $SPACE_LITTLE
					span
						font-size: $FONT_DASHBOARD_SPANLIST
						display: inline
					span:first-of-type
						+setBorderRadius($RADIUS_DASHBOARD_LIST)
						padding: $SPACE_TINY $SPACE_LITTLE
						margin: $SPACE_TINY

// DASHBOARD - END


// TABLE - START

div.DivTableContainer, div.DivTableProductContainer
	div.DivTableControl
		span.SpanTablePage
			margin-left: $SPACE_MEDIUM
			margin-right: $SPACE_MEDIUM
			font-size: $FONT_SPAN_TABLEPAGE
		label
			margin-right: $SPACE_MEDIUM
			white-space: nowrap
			font-size: $FONT_LABEL_GENERAL
	ul.ListTableThumbnails
		figure
			figcaption
				font-size: $FONT_LABEL_GENERAL
	ul.ListTableTicket, ul.ListTableConversation
		div.DivTicketItem, div.DivConversationGeneral
			div.DivTicketSubItem, div.DivConversationItem
				div.DivTicketHeader, div.DivConversationHeader
					span
						display: block
					span.SpanTicketNumber, span.SpanConversationDate
						font-size: $FONT_DATA_TICKETLABEL
					span.SpanTicketTitle, span.SpanConversationTitle
						font-size: $FONT_LIST_TABLETITLE
					span.SpanConversationDate
						text-align: right
					span.SpanTicketNumber
						display: inline-flex
						p
							margin-left: 5px
							border-left: solid 4px white
							padding-left: 5px
				div.DivTicketTail, div.DivConversationStatus
					span
						margin-right: $SPACE_MEDIUM
						vertical-align: middle
						display: inline-block
						padding-top: $SPACE_TINY
						padding-left: $SPACE_LITTLE
						padding-right: $SPACE_LITTLE
						padding-bottom: $SPACE_TINY
						font-size: $FONT_SPAN_TICKETSTATUS
						+setBorderRadius($RADIUS_TICKET_STATUS)
					span:first-of-type
						margin-left: 0px
					span:last-of-type
						margin-right: 0px
		li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						span.SpanConversationDate
							text-align: left
		dl
			dt, dd
				margin-left: 0px
				margin-right: 0px
				margin-bottom: $SPACE_THIN
				margin-top: $SPACE_THIN
				font-size: $FONT_DATA_TICKETLABEL
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
	table.TableListDetails
		font-size: 0.9em
		font-weight: 600

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	div.DivForm
		div.DivListControl
			h3
				margin: 0px
			div.DivListControlButton
				label
					margin-left: $SPACE_LITTLE
					margin-right: $SPACE_LITTLE
					font-size: $FONT_SPAN_TABLEPAGE
				label, input[type=button]
					display: inline-block

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationHeader
				h3
					margin: 0
				div.DivConversationInfo
					div.DivConversationInfoDetails
						span
							margin: 0px
							text-align: right
			div.DivConversationContent
				div.DivAttachmentContainer
					label
						margin: 0px
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					margin: 0px
					font-family: $URL_FAMILYSECONDARY_PRIMARY
					color: white
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
					width: $SPACE_BIG
					height: $SPACE_ENORMOUS
					padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
					text-align: center

// DYNAMIC CONTAINER - END


// LOADING - START

div#divCurtainLoading
	div#divLoadingMessage
		span#spanLoadingPercentage
			margin-bottom: $SPACE_HUGE
			font-size: $FONT_SPAN_LOADINGPERCENTAGE
		span
			font-size: $FONT_LABEL_GENERAL

// LOADING - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	span
		font-size: $FONT_LABEL_GENERAL
		font-weight: bold
	input#buttonNotificationClose
		font-size: $FONT_SIZE_CLOSE
	p#paragraphNotificationInstallIOS
		display: none
div#divNotificationInstallList
	span
		display: block
		margin-bottom: $SPACE_TINY

// NOTIFICATION - END


// APPROVAL - START

div.DivForm
	div.DivCard, div.DivCardFinish
		span#spanTitle
			font-size: 12pt
		span#spanName
			margin-top: -7.5px
			font-size: 16pt

// APPROVAL - END


// ABOUT - START

div.DivGeneralProduct
	label, p
		margin-left: $SPACE_MEDIUM
	ol
		li
			margin-left: $WIDTH_LAYOUT_LABELABOUT
			font-size: $FONT_LIST_ABOUT
		ul
			li
				margin-left: $WIDTH_LAYOUT_SUBLABELABOUT
				list-style-type: lower-alpha
				font-size: $FONT_LIST_ABOUT
		label.LabelSubProduct, label.LabelProduct
			margin: 0px
			font-size: $FONT_LIST_ABOUT
			margin-top: 5px
		label.LabelProduct
			margin-left: $WIDTH_LAYOUT_LABELABOUT




// ABOUT - END